(function(plugin) {
  //Methods
  var busy = null,
    defaults = {
      form: 'form',
      ajax: false,
      onValidation: null,
      onFormSent: null
    };

  function mergeObjects(defaults, options) {
    var settings = {};
    for (var defaultName in defaults) {
      settings[defaultName] = defaults[defaultName];
    }
    for (var optionName in options) {
      settings[optionName] = options[optionName];
    }
    return settings;
  }

  function callback(fn, param1, param2) {

    if (typeof fn === 'function') {

      fn(param1, param2);

    } else if (fn !== null && typeof fn !== 'function') {
      console.log('The provided callback is not a function.');
    }

  }

  function validate(inputs, options) {
    var errors = false;

    function checkInput(input) {
      var error = false,
        value = input.value.trim();

      if (value === '' || value === '0') {

        error = true;

      }

      callback(options.onValidation, {
        form: document.querySelector(options.form),
        input: input,
        error: error
      });

      return error;
    }

    //Test if one or more inputs
    if (inputs.nodeType) {
      errors = checkInput(inputs);
    } else {
      [].map.call(inputs, function(input) {
        errors = checkInput(input);
      });
    }

    return errors;
  }

  function send(options) {
    var form = document.querySelector(options.form),
      action = form.hasAttribute('data-form-action') ? form.getAttribute('data-form-action') : form.getAttribute('action'),
      data = new FormData(form),
      xhr = new XMLHttpRequest();
    if (!busy) {
      busy = true;

      xhr.open('POST', action, true);
      xhr.onreadystatechange = function() {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          callback(options.onFormSent, form, xhr.responseText);
          busy = false;
        }
      };

      xhr.send(data);
    } else {
      console.log('busy');
    }
  }

  function setListeners(options) {
    var form = document.querySelector(options.form),
      submit = form.querySelector('[type="submit"]'),
      inputs = form.querySelectorAll('[required]');

    submit.addEventListener('click', function(e) {
      var errors = validate(inputs, options);

      if (options.ajax && !errors) {
        send(options);
      }

      if (options.ajax || !options.ajax && errors) {
        e.preventDefault();
      }
    });

    [].map.call(inputs, function(input) {

      input.addEventListener('keyup', function() {
        validate(input, options);
      });
    });
  }

  plugin.init = function(options) {

    var settings = mergeObjects(defaults, options);

    if (options && typeof options === 'object') {
      setListeners(settings);
    } else {
      console.error('ajaxForm: argument must be an object');
    }

  };

})(this.validateForm = this.validateForm || {});

(function(document) {
  'use strict';
  var progress = 0,
    defaults = {
      selector: 'img',
      progress: null,
      always: null,
      done: null,
      images: []
    };

  //Private functions
  function mergeObjects(defaults, options) {
    var settings = {};
    for (var defaultName in defaults) {
      settings[defaultName] = defaults[defaultName];
    }
    for (var optionName in options) {
      settings[optionName] = options[optionName];
    }
    return settings;
  }

  function callback(fn, param1, param2) {
    if (fn !== null && typeof fn === 'function') {
      fn(param1, param2);
    } else if (fn !== null) {
      console.log('Callback not defined or not a function');
    }
  }

  function watchProgress(settings, img) {
    var percentage = 0,
      total = settings.imageCount,
      errors = false;

    progress++;

    percentage = progress / total * 100;

    callback(settings.progress, img, percentage);

    if (progress === total) {
      callback(settings.always, settings.images);

      settings.images.forEach(function(img) {

        if (!img.loaded) {
          errors = true;
        }
      });

      if (!errors) {
        callback(settings.done, settings.images);
      }
    }
  }

  function load(el, settings) {
    var isBackground = el.hasAttribute('data-background-src'),
      src,
      obj = {},
      img = new Image();

    src = isBackground ? el.getAttribute('data-background-src') : el.getAttribute('src');

    obj.element = el;
    obj.src = src;

    img.src = src;

    img.onload = function() {

      obj.loaded = true;

      settings.images.push(obj);

      if (isBackground) {
        el.style.backgroundImage = 'url(' + src + ')';
      } else {
        el.setAttribute('src', src);
      }

      watchProgress(settings, obj);
    };

    img.onerror = function() {
      obj.loaded = false;

      settings.images.push(obj);

      watchProgress(settings, obj);
    };
  }

  //Public method
  document.imgPreloader = function(options) {

    var images = Array.prototype.slice.call(document.querySelectorAll(options.selector)),
      settings = mergeObjects(defaults, options);

    settings.imageCount = images.length;

    images.forEach(function(img) {
      load(img, settings);
    });

  };

})(document);

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        document.imgPreloader({
          selector: '.preload',
          progress: function(img, percentage) {
            if (img.loaded) {
              img.element.classList.add('loaded');
            }
          }
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // var frame = document.querySelector('.home-header iframe');
        //
        // frame.onload = function() {
        //   frame.classList.add('active');
        // };
      }
    },

    'contact': {
      init: function() {
        var form = document.querySelector('.contact-form');

        function notify(response, message, form) {
          alert = form.querySelector('.alert');

          if (response === 'success') {
            alert.classList.remove('alert-warning');
            alert.classList.add('alert-success');
          } else {
            alert.classList.remove('alert-success');
            alert.classList.add('alert-warning');
          }

          alert.innerHTML = message;
          alert.classList.add('toggle');
          setTimeout(function() {
            alert.classList.remove('toggle');
          }, 3000);
        }

        validateForm.init({
          form: '.form-contact',
          ajax: true,
          onValidation: function(inputData) {
            var container = inputData.input.parentElement;

            if (inputData.error) {
              container.classList.remove('has-success');
              container.classList.add('has-error');
              notify('error', 'Veuillez remplir tous les champs requis.', inputData.form);
            } else {
              container.classList.remove('has-error');
              container.classList.add('has-success');
            }
          },
          onFormSent: function(form, response) {
            if (response === '"success"') {
              form.reset();
              notify('success', 'Votre message a été envoyé.', form);
            } else if (response === '"error"') {
              notify('error', 'Une erreur est survenue, veuillez renvoyer le formulaire.', form);
            }
            console.log(response);
          }
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
